import React, {useState} from 'react'

const mystyle = {
    "textinput": {
        textDecoration: "none",
        display: "inline-block",
        border: "none", 
        marginTop: "0.5em", 
        borderRadius: "7px",
        fontSize: "14px",
        height: "2em",
        outline: "none",
        width: "100%",
        padding: '3px 15px',
    }
  };
  

function TextInput(props){
    const {onBlur} = props
    let id = `input${props.id}`
    const [value, setValue] = useState(props.value)

    const changeInput = (e) => {
        setValue(e.target.value)
        console.log(e.target.value)
        if (typeof props.onChange === "function")
            props.onChange(e.target.value);
    }

    return(
        <input required style={mystyle.textinput} 
            name={props.placeholder} color="primary" 
            value={value} id={id} placeholder={props.placeholder} 
            onChange={changeInput} onBlur={onBlur!==undefined?props.onBlur:()=>{}} ></input>
    )
}

export default TextInput;

import React, {useState} from 'react'
import {Label, IntegerInput } from '../../Components'

const mystyle = {
    'idea':{
        'backgroundColor': 'white',
        'color': 'black',
        'borderRadius': '5px',
        'padding': '0.5em 6px',
        'marginTop': '20px',
        'border': '2px solid rgb(67, 225, 222)',
    },
    "ideas": {
        width: "100%",
    }
}

/** 
 * Muestra las ideas de forma individual
 * 
 * @version 0.1
 * @component {Ideas} Muestra una idea
 */
function Idea({idea, index}) {
    const [value, setValue] = useState(0);

    return (<div key={idea.id_idea} style={mystyle.ideas} className="idea">
        <div style={mystyle.idea}>
            <Label size={'s'} title={`${idea.idea_texto}`} />
        </div>
        <IntegerInput value={ value } min={0} max={5} 
            placeholder={"Puntaje"}
            id={`inputpuntaje${idea.id_idea}`} 
            onChange={ (value) => setValue(value) }/>
    </div>)
};

export default Idea
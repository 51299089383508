import React, {useState} from 'react'
import {Label, InputButton,
    NotifMissingInput }from '../../Components/index'


/** 
 * Permite el ingreso del nombre del usuario y
 * genera el inicio del juego
 * 
 * @version 0.1
 * @component {Join} Ingreso a la sesion 
 */
function Join(props){  
    const placeholderText = "Nombre o nickname"
    const labelText = "¿Cuál es tu nombre?";
    const buttonText = "INGRESAR";
    const [emptyInput, setEmptyInput] = useState(false)

    let saveUser = async () => {
        let user = document.getElementById("inputUsername");

        if (user.value === ""){
            setEmptyInput(true);
        }
        else{
            await props.saveUser(user.value)
            props.onClick()
        }
    }
   
    return (
        <div style={props.style.divScreen} className="join-screen">
            <Label size={'m'} title={"RETO"} />
            <Label size={'b'} title={props.reto} />
            <div style={props.style.divScreen, {'marginTop': '50px'}} className="share-link">
                <Label size={'s'} title={labelText}/>
                <InputButton id={"Username"} placeholder={placeholderText} 
                buttonText={buttonText} onClick={saveUser}/>
            </div>
            {emptyInput && <NotifMissingInput username={true}/>}
        </div>
    );  
}

export default Join;
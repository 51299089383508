import React from 'react';
import logo from './logo.png';
import TagLine from './TagLine'
import { useHistory } from "react-router-dom";

let marginBot = "50px"

/** 
 * Header de la aplicacion, muestra logo c/s tag line
 * dependiendo del estado en que se encuentra
 * 
 * @version 0.1
 * @component {Header} Muestra el header 
 */
function Header(props){
    const logoClass = props.Home ? "App-logo-top":"App-logo-left";
    const message = "Creatividad para todos";
    const history = useHistory();

    if (props.Crush){
        marginBot = "20px"
    }
    else if (props.Home){
        marginBot = "50px"
    }
        
    return(
        <header style={{'flexDirection': props.Home ? 'column' : '',
                        'alignItems': 'center', 'marginBottom': marginBot}}
            id="header" className="App-header">
            <img src={logo} className={logoClass} 
                onClick={() => {history.push('/'); props.goHome() }} alt="logo"/>
            {props.Home && <TagLine message ={message}/>}
        </header>
    )
}

export default Header;
import React, {useState} from 'react';
import {Label, TextInput,
    InputButton, NotifMissingInput } from '../../Components/index'

/** 
 * Permite el ingreso de variables y se pueden 
 * agregar hasta cuatro variables
 * 
 * @version 0.1
 * @component {Crear} View para crear variables del reto
 */
function Crear(props){
    const [nVariables, addVariables] = useState(2);
    const [saveVariables, addVariable] = useState(["","","","","", ""])
    const [emptyInput, setEmptyInput] = useState(false)
    const [fourVariables, setfourVariables] = useState(false)

    let localVariables = [...saveVariables]

    const add = () => {
        if (nVariables<6){
            addVariables(nVariables+1);
            addVariable(localVariables);
        }
        if (nVariables+1>5)
            setfourVariables(true)
    };

    const onVariableChange = (variable, k) =>{
        localVariables[k] = variable;
    }
    
    const crearReto = () => {
        const isEmpty = (v) => v === "";

        if (localVariables.every(isEmpty)){
            setEmptyInput(true)
        }
        else{
            setEmptyInput(false)
            addVariable(localVariables);
            props.onVariableChange(nVariables);
            props.onClick();
        }
    }

    const remove = (index) => {
        const newVars = saveVariables.filter((v, i)=>i!==index)
        newVars.push("");
        if(nVariables>1){
            addVariable(newVars)
            addVariables(nVariables-1)
        }
        setfourVariables(false)
    }

    let Variables = () => {
        return <div style={{'marginTop':'20px','display':'flex',
                        'flexDirection':'column','alignItems':'center',}} 
                    className="variables">
            {[...Array(nVariables)].map((e, i) => {
            return (
                <div key={i} style={{'display':'flex', 'alignItems':'center'}}>
                    <TextInput placeholder={`Variable ${i+1}`} value={saveVariables[i]} 
                        onChange={(v) => onVariableChange(v, i)} id={`Variable${i+1}`}
                        onBlur = {()=>addVariable(localVariables)}/>
                    <p style={{'fontSize': '28px', 
                        'cursor':'pointer', 'margin':'0', 
                        'padding':'0'}} 
                        onClick={()=>remove(i)}>&nbsp;-</p>
                </div>)
        })}</div>;
    };
      
    return (
        <div style={props.style.divScreen} className="crear-screen">
            <Label size={'m'} newcolor={"rgba(255,255,255,0.7)"} 
                title={"RETO"} />
            <Label size={'b'} title={props.reto} />
            <Variables/>
            {!fourVariables &&
                <p style={{'fontSize': '14px',  'cursor':'pointer'}} onClick={add}>+ Agregar una variable más</p>}
            {fourVariables && <div style={{height:'16px'}}></div>}
            <InputButton input={false} buttonText={"CREAR RETO"} onClick={crearReto}/>
            {emptyInput && <NotifMissingInput variables={true}/>}
        </div>
    )

}

export default Crear;
import React from 'react'
import Label from './Label';

const NotifMissingInput = (props) =>{
    let message = ""
    if (props.variables){
        message= "Debe escribir como minimo una variable"
    }
    else if (props.reto){
        message = "Escriba el nombre del reto"
    }
    else if (props.username){
        message = "Indique su username para continuar"
    }

    return <div style={{color:'yellow'}}><Label size={'s'} title={message}/></div>
}

export default NotifMissingInput
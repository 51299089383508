import React, {useState} from 'react';

import {Label, Button, NotifMissingInput, IntegerInput } from '../../Components'



const mystyle = {
  "textinput": {
      textDecoration: "none",
      display: "inline-block",
      border: "none", 
      marginTop: "0.5em", 
      borderRadius: "7px",
      fontSize: "14px",
      height: "2em",
      outline: "none",
      width: "100%",
      padding: '3px 40px',
  }
};

/** 
 * 
 * 
 * @version 0.2
 * @component {Tiempo} Seleccion del tiempo
 */
function Tiempo (props){
    //const id = "home"
    const labelText = "¿Cuánto tiempo quieres trabajar en este reto?";
    const buttonText = "SIGUIENTE";
    const [duracion, setDuracion] = useState(1)

    return (
        <div style={props.style.divScreen} className="home-screen screen">
          <Label size={'m'} newcolor={"rgba(255,255,255,0.7)"} 
                title={"RETO"} />
          <Label size={'b'} title={props.reto} />
          <Label size={'s'} title={labelText}/>
          <div style={{'height':'30px'}}></div>
          <IntegerInput value={ duracion } min={1} max={60}
              placeholder={"Entre 1 a 60 minutos"}
             id={`inputDuracion`} 
            onChange={ (value) => setDuracion(value) }/>
          <div style={{'height':'30px'}}></div>
          <Button input={false} buttonText={buttonText} onClick={() => props.onClick(duracion)}/> 
        </div>
    )
}

export default Tiempo

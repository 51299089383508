import React from 'react'

import {Label, Button } from '../../Components/index'

/** 
 * Muestra los usuario que ya estan en 
 * un estado de finalizado
 * 
 * @version 0.1
 * @component {Waiting} Lista de usuario
 */
function Waiting(props){
    const label = "¡ESPEREMOS A LOS DEMÁS!"
    const buttonText = "SIGUIENTE";
    
    const {todosListos} = props;
    let Usuarios = () => {
        return <div style={{'display':'flex', 'flexDirection':'column', 'textAlign': 'left',
                    'width': '100%', 'marginBottom': '30px', 'padding': '0px 30px'}} className="variables">
                <Label size={'s'} title={`Ya terminaron (${props.users.length}/${props.totalUsers})`} />
                {props.users.map((e, i) => 
                    <Label key={i} size={'s'} title={`${i+1}. ${e.nombre}`} />)}
            </div>;
    };

    return (
        <div style={props.style.divScreen} className="waiting-screen">
            <Label size={'b'} title={label} />
            <div style={{marginBottom:"40px"} }></div>
            <Usuarios/>
            <Button buttonText={buttonText} class={todosListos ? '':'disable'}onClick={props.onClick}/>
            {todosListos && <p>Todos estan listos!</p>}

        </div>
    ); 
}

export default Waiting
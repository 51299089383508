export default class Idea{
    constructor(id, content, user, variable, sig){
        this.id = id;
        this.content = content;
        this.user = user;
        this.variable = variable;
        this.significado = sig;
        this.puntaje = 0;
    }

    addPuntaje(pnt){ this.puntaje = pnt}
}

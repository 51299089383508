import React from 'react';
import TextInput from './TextInput';
import Button from './Button.js'

/** 
 * Permise el ingreso de un texto y 
 * muestra un boton
 * 
 * @version 0.1
 * @component {InputButton} Input más botón
 */
function InputButton(props){
    let input = props.input === false ? false : true;
    let id = props.id ? props.id : props.placeholder;
    return(
        <React.Fragment>
            {input && <TextInput placeholder={props.placeholder} value={''} id={id}/>}
            <Button onClick={props.onClick} buttonText={props.buttonText}/>
        </React.Fragment>
    )
}

export default InputButton;
import React from 'react'
import {Label, Button } from '../../Components/index'
import Timer from '../../../Logic/Timer'

const mystyle = {
    "textinput": {
        textDecoration: "none",
        display: "inline-block",
        border: "none", 
        marginTop: "0.5em", 
        borderRadius: "7px",
        fontSize: "14px",
        height: "2em",
        outline: "none",
        width: "100%",
        padding: '3px 40px',
    },
    "button": {
            color: "white",
            padding: "5px 10px",
            display: "inline-block",
            textAlign: "center",
            border: "none", 
            width: '30%',
            marginTop: "1em", 
            borderRadius: "7px",
            outline: "none",
            fontWeight: '700',
            transition: 'all 0.4s ease-in-out',
            cursor: 'pointer',
            fontSize: '14px',
        }
  };
  
  
/** 
 * Muestra frases basadas en variables, permite
 * el ingreso de ideas todo esto en un tiempo 
 * determinado
 * 
 * @version 0.1
 * @component {Play} Maneja el juego de Crush
 */
class Play extends React.Component{
    constructor(props){
        super(props)
        this.placeholderText = "¿Qué idea se te viene a la cabeza?"
        this.buttonText = "GUARDAR IDEA";

        this.state = {
            timesUp:false,
            crrIdea: 0,
        }
        this.setTimesUp = (state) => {this.setState({timesUp: state})}
        this.setIdea = (state) => {this.setState({crrIdea: state})}
        console.log(this.props.time, "Tiempo")
    }

    nextIdea = () => {
        this.setIdea(this.state.crrIdea + 1 >= this.props.ideas.length ? 0 : this.state.crrIdea +1)
    }


    newIdea = () => {
        let idea = document.getElementById("text-ideas");
        let last = false
        if (this.state.timesUp){
            this.props.onTimeFinished();
            last = true
        }
        if (idea.value !== "")
            this.props.saveIdea(idea.value, this.props.ideas[this.state.crrIdea], last);
        idea.value = "";
        this.nextIdea()
        console.log (this.props.ideas, "Ideas")

    }

    render(){
        const {ideas, reto, style, tiempo} = this.props;
        const {crrIdea} = this.state;

        const pregunta = <div> {`Imagina que ${ideas[crrIdea].frase}`}
                    <br />{`¿cómo esto ayudaría a ${reto.toLowerCase()}?`}
                </div>;
                
        return (
            <div style={style.divScreen} className="play-screen">
                <div style={{display:"flex", alignContent:"center", width:"100%",
                    justifyContent:"space-between", marginBottom:"15px", marginTop:'0'}}>
                <Label size={'m'} newcolor={"rgba(255,255,255,0.7)"} title={"¡INSPÍRATE!"} />
                <Timer startTime={tiempo[0]} seconds={tiempo[1]}  
                    timeFinished={() => {this.setTimesUp(true);}}/>
                </div>
                <Label size={'m'} extra={true}  title={pregunta} />
                <button style={mystyle.button}  onClick={this.nextIdea} ><i style={{alignSelf: 'center', justifySelf:'center', cursor: 'pointer'}}
                    className="fas fa-redo-alt fa-lg"></i></button>
                <div style={{marginTop:'30px'}}></div>
                <textarea style={mystyle.textinput} maxLength="300" className="text-ideas" id="text-ideas" 
                    style={{'height':'120px', padding: '10px 14px','width':'230px', 
                        'borderRadius':'7px','fontFamily': 'Roboto'}} 
                    placeholder={this.placeholderText}></textarea>
                <Button onClick={this.newIdea} buttonText={this.buttonText}/>
            </div>
        ); 
    }
    }
   

export default Play
import React from 'react';
import './css/App.css';

import HomeFrames from './HomeFrames';
import CrushFrames from './CrushFrames';

import { Header } from './Presentational/index';
import {Button } from './Presentational/Components'

import {
  BrowserRouter as Router,
  Switch,Route, Link } from "react-router-dom";


import Sesion from './Logic/Sesion';
import ApiReto from './Logic/ApiReto';

const mystyle = {
  'divScreen':{
      'width':'230px',
      'display':'flex', 
      'flexDirection':'column',
      'alignItems':'center',
  }
}

export const URLcontext = React.createContext()

/**
 * 
 * Class App  is the main component of the app it calls the
 * it also manages the API and it records the information of the 
 * Sesion. 
 * 
 * @version 0.1
 * @component {App} Controlador de la aplicacion
 * 
 * - TODO: Separar estilos de los componentes
 */
class App extends React.Component{

  /**
   * Represents the App.
   * @constructor
   */
  constructor(props){
    super(props)
    this.apiReto = new ApiReto();
    this.state = {
      Home: true,
      Play: false,
      Crush: false,
      wait: true,
      sesion: new Sesion(),
    };

    URLcontext.displayName = 'URLcontext';
    this.startCrush = this.startCrush.bind(this)
    this.goToCrush = this.goToCrush.bind(this)
    this.createSesion = this.createSesion.bind(this)
    this.verificarLink = this.verificarLink.bind(this)
    this.setVariables = this.setVariables.bind(this)
    this.setFrases = this.setFrases.bind(this)
  }

  setHome = (state) => {this.setState({Home: state})}
  setPlay = (state) => {this.setState({Play: state})}
  setCrush = (state) => {this.setState({Crush: state})};

  componentDidMount() {
    this.setState({wait:true})
    fetch(this.verificarLink())
      .then((ans)=>
        !ans?this.setState({wait: false}):"")
  }

  /**
   * Se crea la sesión de crush 
   * llamada a API para generar las frases
   * @param  {Array} variables
   */
  async startCrush(variables){
    let newSesion = this.state.sesion;

    this.setState({wait: true})
    this.apiReto.getFrases(this.state.sesion.idReto, this.state.sesion.id)
      .then(frases => {
        newSesion.frases = frases;
        this.setState({
          sesion: newSesion,
          wait: false,
          Play: true,
        })
      console.log("VARIABLES", this.sesion)
    })
  }

  /**
   * Función que llama al API 
   * para crear un reto 
   * @param  {string} reto
   */
  async createSesion(reto){
    this.setState({wait: true})

    let newSesion = this.state.sesion;
    newSesion.setReto(reto);

    this.apiReto.crearReto(reto)
    .then(ans =>{
        this.setState({
          sesion: newSesion,
          wait: false,
        })
      }
    )
  }

  
  setVariables = (variables) => {
    let vars = new Set(variables)
    let copySesion = this.state.sesion
    copySesion.setVariables(vars)

    this.setState({sesion: copySesion})
  } 

  /**
   * Funcion que llama al API para agregrar las 
   * variables al reto 
   * @param  {Array} variables
   */
  setFrases = (variables, duracion) =>{
    let newSesion = this.state.sesion;
    duracion = parseInt(duracion);
    newSesion.setVariables(variables);
    newSesion.setDuracion(duracion);

    console.log("----this is a set up------ ", duracion, variables )
    this.setState({wait: true})
    this.apiReto.actualizarReto(variables, duracion)
    .then(
      this.setState({
        sesion: newSesion,
        wait:false})
    )
  }
  
  
  /**
   * Funcion que cambia los estados para
   * mostrar la primera pantalla Home
   */
  goBackHome = () => {
    this.setHome(true);
    this.setPlay(false);
    this.setCrush(false);
  }
  
  /**
   * Muestra un boton que inicia Crush
   * @param {Array} variables variables del juego
   * @param {out} out  
   * @returns {JSX}
   */
  goToCrush = (variables, out) => {
    return (<Link style={{'width':'100%'}}to={`/${this.state.sesion.id}/${this.state.sesion.idReto}`}>
      <Button full={true} buttonText={"INICIAR RETO"} onClick={
        async () => {await this.startCrush(variables); out()}}></Button>
    </Link>)
  }

  verificarLink = async () => {
    const path = window.location.pathname.replace("/", '').split("/")
    const url = window.location.href;
    console.log(path, url)
    if (path.length > 1){
      return (this.apiReto.checkReto(url, path[0], path[1])
        .then(async data => {
          console.log(path.length)
          this.apiReto.getFrases(path[1])
          .then(() => {
            if(data!=="error")
              this.setState({Home: false, Play: true, sesion: data, wait:false});
            else{
              //TODO-SHOWERROR
              this.setState({wait:false})
            }
          })
      })
      .catch(err => { console.log(err)}))
    }
    else{
        this.apiReto.iniciarReto()
          .then(data => {
            this.setState({sesion: data, wait:false})
          })
          .catch(err => { console.log(err)});
    }
    return false
  }

  content = () =>{
    const Play = this.state.Play;
    const Wait = this.state.wait;

    const route = this.state.sesion.id

    return (    
    <React.Fragment>
        {!Wait && Play && <CrushFrames route={route} goBackHome={this.goBackHome} 
                  style={mystyle} startTime={this.setCrush} variables={this.state.sesion.variables}
                  reto={this.state.sesion.reto} setVariables={this.setVariables}
                  frases={this.state.sesion.frases} API = {this.apiReto}/>}
    </React.Fragment>
  )}

  NoMatchPage = () => {
      if (this.state.wait)
      {return (
        <div className="lds-hourglass"></div>
      );}
      else{
        return (
          <h3>Reto no Encontrado!</h3>
        );
      }
  };
  
  render(){
    const Home = this.state.Home;
    const Crush = this.state.Crush;
    const Wait = this.state.wait;
    const Play = this.state.Play;

    const url = this.state.sesion.url
    const route = this.state.sesion.id

    console.log("",Wait)
    return (
      <Router>
      <div style={{display: Home ? 'grid' : 'flex', flexDirection:'column'}} className="App App-container">
        <Header goHome={this.goBackHome} Home={Home}  Crush={Crush}/>
        
        <div className="App-content">
        <Switch>
          <Route exact path={`/${this.state.sesion.id}/${this.state.sesion.idReto}`} 
            to={`/${this.state.sesion.id}/${this.state.sesion.idReto}`} children={this.content}>
          </Route>
          <Route exact path="/">
            <URLcontext.Provider value={{url:url, route:route}}>
            {!Wait && !Play && <HomeFrames style={mystyle}  goToCrush={this.goToCrush} 
                            leaveHome={() => this.setHome(false)} Home={Home} 
                            crearSesion={this.createSesion} setFrases={this.setFrases} 
                            sesion={this.state.sesion}/>}
            </URLcontext.Provider>
          </Route>
          <Route component={this.NoMatchPage} />
          
        </Switch>
        </div>
      </div>
    </Router>
    );
  }  
}

export default App;
import React, {useState} from 'react';

const mystyle = {
"input": {
    textDecoration: "none",
    display: "inline-block",
    border: "none", 
    marginTop: "0.5em", 
    borderRadius: "7px",
    fontSize: "14px",
    height: "2em",
    outline: "none",
    width: "100%",
    padding: '3px 15px',
},
}

const isValid = (value, min, max) =>
  value !== '' &&
  value !== '-' &&
  (min === undefined || value >= min) &&
  (max === undefined || value <= max);


/** 
 * Limita el ingreso de únicamente número 
 * 
 * @version 0.1
 * @param  {number} value - valor actual de input
 * @param  {number} min - valor minimo del numero
 * @param  {number} max - valor máximo del numero
 * @type  {onChange} onChange - guardar el nuevo valor
 * @param  {string} id
 * 
 * @component {IntegerInput} Input para numeros
 */
export const IntegerInput = ({ value, min, max, onChange, id, placeholder }) => {
  const regexp = new RegExp(`^-?[0-9]*$`);
  const [internalValue, setInternalValue] = useState();
  const [valid, setValid] = useState(isValid(value, min, max));

  
  return (
    <input type="text"
            id = {id} placeholder={placeholder}
           className={ valid ? '' : 'invalid' }
           value={ internalValue } style={mystyle.input}
           onChange={ (event) => {
             const newValue = event.target.value;
             if (regexp.test(newValue)) {
               setInternalValue(newValue);
               let newValid = isValid(newValue, min, max);
               setValid(newValid);
               if (newValid) 
                 onChange(newValue);
             }
           } }
           onBlur={ () => {
             if (internalValue < min) {
               setInternalValue(min);
             } else if (internalValue > max) {
               setInternalValue(max);
             } else {
               setInternalValue(value);
             }
             setValid(true);
           } }
    />
  );
};

export default IntegerInput
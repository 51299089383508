import React, {useState} from 'react'
import {Label } from '../../Components/index'

import { URLcontext } from '../../../App'
import {CopyToClipboard} from 'react-copy-to-clipboard';

/** 
 * Se ve el link para compartir el reto, permite copiarlo
 * y pasar a iniciar el reto
 * 
 * @version 0.1
 * @component {ShareLink} View para compartir el reto
 */
const mystyle = {
    "textinput": {
        backgroundColor: "white", 
        textDecoration: "none",
        display: "inline-block",
        border: "none", 
        marginTop: "0.5em", 
        borderRadius: "7px",
        fontSize: "14px",
        height: "2em",
        outline: "none",
        width: "100%",
        padding: '3px 10px',
    }
  };

function ShareLink(props){
    const [copied, changeCopied] = useState(false)

    return (
        <div style={props.style.divScreen} className="share-screen screen">
            <Label size={'m'} newcolor={"rgba(255,255,255,0.7)"} title={"RETO"} />
            <Label size={'b'} title={props.reto} />
            <URLcontext.Consumer>
                {value => {
                    return (<React.Fragment>
                        <div style={props.style.divScreen, {'marginTop': '50px'}} className="share-link">
                            <Label size={'s'} title={'¡Comparte el Reto!'}></Label>
                            <div style={{display:'flex'}}>
                                <input value={value.url} style={mystyle.textinput}disabled={true}></input>
                                <CopyToClipboard text={value.url} onCopy={() => changeCopied(true)}>
                                   <i style={{alignSelf: 'center', marginLeft: '5px', cursor: 'pointer'}} 
                                        class="fas fa-copy fa-lg"></i>
                                </CopyToClipboard>
                            </div>
                            {copied ? 
                                <Label size={'m'} title={"Copiado"} newcolor={'rgb(147, 237, 199)'}/> : null}
                            <div style={props.style.divScreen, {'marginTop': '50px', 'width':'100%'}} className="share-link">
                        {props.goToCrush()}
                        </div>
                        </div>
                        
                        
                    </React.Fragment>)
                }}
            </URLcontext.Consumer>
        </div>
    );  
}
export default ShareLink;
import React, { useState } from 'react'
import Idea from './Logic/Idea'
import User from './Logic/User'

import {Join, Play, Waiting, Vote, Ranking} from './Presentational'

let interval = []

/** 
 * Se encangar de manejar los estados de la aplicacion 
 * cuando la persona esta jugando Crush
 * 
 * @version 0.1
 * @component {CrushFrames} Controla las vistas del juego
 */
function CrushFrames(props) {
  const [joinSession, setJoin] = useState(true)
  const [joinCrush, setCrush] = useState(false)
  const [joinWaiting, setWaiting] = useState(false)
  const [joinVote, setVote] = useState(false)
  const [joinRanking, setRanking] = useState(false)

  const [user, addUser] = useState([]);
  const [userIdeas, addIdeas] = useState([]);
  const [count, addCount] = useState(0);

  props.frases.map((e, i) => {e.key=i})
  const promps = props.frases

  let onFinishCrush = () => {
    props.startTime(false);

    props.API.cambiarEstado(user.id, 1)
    .then(async ()=> {
      user.cambiarEstado(1)
      await areWeWaiting(); setWaiting(true); setCrush(false);})
    //TODO - Make the user state 1.
    interval = setInterval(async () => await areWeWaiting(), 10000);
  }

  let onStartCrush = () => {
    setJoin(false); setCrush(true);
    props.startTime(true)
  }

  const [tiempo, setTime] = useState([])
  let saveUser = async (user) => {
    let userinfo = await props.API.addNewUser(user)
    const time = userinfo.tiempo_restante.split(":");

    setTime([parseInt(time[0]) , parseInt(time[1])])

    console.log('#####', time);
    let newUser = new User(await userinfo.id_jugador, user)
    addUser(newUser)
  }

  let saveIdea = (idea, frase) => {
    let newIdea = new Idea(count, idea, user.id, frase.id_variable, frase.id_frase)
    addCount(count+1)

    props.API.saveIdea(newIdea)
  }

  const [ranking, setVarRanking] = useState([])
  let lastVote = async (variable) => {
    votar(variable)

    console.log("IMPORTANTE ", userIdeas)
    props.API.cambiarEstado(user.id, 3)
      .then(async ()=>{await areWeWaiting(); 
          user.cambiarEstado(3)
          setWaiting(true); setVote(false);})

    //TODO - Make the user state 3
    interval = setInterval(async () => await areWeWaiting(), 10000);
  }

  let votar = async (variable) => {
    let ids = []
    let ranks = []
    
    for (let e of userIdeas){
      if(e.variable_texto === variable){
        let pnt = document.getElementById(`inputpuntaje${e.id_idea}`)
        ids.push(e.id_idea)
        ranks.push(parseInt(pnt.value));
      }
    }
    props.API.calificarIdeas(ids, ranks, user.id)
  }

  const [todosListos, setTodosListos] = useState(false)
  const [waitingList, setWaitingList] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)
  let areWeWaiting = async () => {
    function isFinished(l){
      console.log(l)
      for (let e of l){
        //if ( || e.estado == 0 || e.estado== 2)
        if(e.estado==null || user.estado>e.estado)
          return false
      }
      return true;
    }

    props.API.getListadeEspera()
    .then(async (waitingL) => {
      setTotalUsers(waitingL.length)
      let ready = waitingL.filter(e => (Number.parseInt(e.estado) in [1,3,4] 
          && Number.parseInt(e.estado)!==2
          || (e.estado>=user.estado)))

      setWaitingList(ready)
      console.log("Esperando ->", user.estado, waitingL,ready, isFinished(waitingL))
      if (isFinished(waitingL)){
        //Ya no hay que esperar más 
        console.log("No esperar", userIdeas,  ranking)
        if (userIdeas.length  === 0){
          console.log("Ideas de lo usuarios")
        }
        else if (ranking.length === 0){
          console.log("Ranking!")
        }
        setTodosListos(true)
        clearInterval(interval)
      } 
      else{
        setTodosListos(false)
      }
    })
  }

  function cambiarEstado(){
    //TODO - Make the user state 1.
    if (todosListos){
      if (userIdeas.length  === 0){
        console.log("Ideas de lo usuarios")
        props.API.getListadeIdeas(user.id)
          .then((ideas)=>{
            let vars = ideas.map((e, i)=>(e.variable_texto))
            props.setVariables(vars)
            setVote(true); setWaiting(false);
            addIdeas(ideas)
            user.cambiarEstado(2)
            props.API.cambiarEstado(user.id, 2)
          })
      }
      else if (ranking.length === 0){
        console.log("Ranking de Ideas")
        props.API.getRanking()
          .then((rank)=> {
            setRanking(true);  setWaiting(false);
            setVarRanking(rank)
            props.API.cambiarEstado(user.id, 4)
            user.cambiarEstado(4)
          })
        }
    }
  }
  ///Finished waiting! -> make state of the user 0, 
  return (
    <React.Fragment>
      {joinSession && <Join style={props.style} reto={props.reto} onClick={onStartCrush} saveUser={saveUser}/>}
      {joinCrush && <Play style={props.style} ideas={promps} tiempo={tiempo} 
                        onTimeFinished={onFinishCrush} saveIdea={saveIdea} reto={props.reto}/>}
      {joinWaiting && <Waiting style={props.style} users={waitingList} 
            onClick={cambiarEstado} todosListos={todosListos} totalUsers={totalUsers}/>}
      {joinVote && <Vote style={props.style} variables={props.variables} 
        API={promps.API} ideas={userIdeas} onClick={lastVote} votar={votar}/>}
      {joinRanking && <Ranking style={props.style} reto={props.reto} ideas={ranking} goBack={props.goBackHome} 
          onClick={() => {setRanking(false)}}/>}
    </React.Fragment>
  );
}

export default CrushFrames
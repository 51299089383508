import React, { useState, useEffect } from 'react';


/** 
 * Recibe el tiempo (segundos y minutos) y lo muestra 
 * en la view
 * 
 * @version 0.1
 * @component {Timer} Tiempo en la aplicación
 * @author [Maria Lopez](mailto:maria@ludik.pe)
 */
const Timer = (props) => {
  const [seconds, setSeconds] = useState(Number.parseInt(props.seconds));
  const [minutes, setMinutes] = useState(Number.parseInt(props.startTime));
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && minutes >= 0 && seconds >= 0
        && !(minutes===0 && seconds===0)) {
      interval = setInterval(() => {
        const nextMin = seconds-1 < 0;
        setSeconds(seconds => nextMin ? 59:seconds-1);
        setMinutes(minutes => nextMin ? minutes-1:minutes)
      }, 1000);
    } 
    else {
      setIsActive(false);
      props.timeFinished();
    }
    return () => clearInterval(interval);

  }, [isActive, seconds, minutes]);

  return (
    <div className="time">
        {minutes>=10?minutes:`0${minutes}`}:{seconds>=10?seconds:`0${seconds}`}
    </div>
  );
};

export default Timer;
import React from 'react';
import {Home, Crear, ShareLink, Tiempo} from './Presentational'


/** 
 * Se encangar de manejar los estados de la aplicacion 
 * cuando la persona esta en el Home
 * 
 * @version 0.1
 * @component {HomeFrames} Controla las vistas del Home
 */
class HomeFrames extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      crrScreen : this.props.Home ? 0 : 1,
      reto: "",
      variables: [],
    }

    this.frameSesion = this.frameSesion.bind(this);
    this.frameSetTime = this.frameSetTime.bind(this);
    this.frameReto = this.frameReto.bind(this);
    this.frameShareLink = this.frameShareLink.bind(this);
  }


  async frameSesion(){ 
    let input = 1;
    let reto = document.getElementById("inputReto").value;
    this.setState({reto: reto});

    if (input){
      this.props.crearSesion(reto)
      .then(ans => {
        this.setState({crrScreen:1});
        this.props.leaveHome()
      })
    };   
  }
  frameReto(){ 
    this.setState({crrScreen:1});
  }

  frameSetTime(){ 
    this.setState({crrScreen:2});
  }

  async frameShareLink(){ 
    this.setState({
      crrScreen: 3,
    })
  }

  onVariableChange = (n) =>{
    let variables = [];

    for (let i = 0; i < n; i++) {
      let id = `inputVariable${i+1}`
      let variable = document.getElementById(id).value;
      if (variable !== "")
        variables.push(variable);
   }
   this.setState({variables: variables});
  }

  startCrush = () => {
    return (this.props.goToCrush(this.state.variables, 
        () => this.setState({crrScreen: 0})));
  }

  onDuracionChange = (duracion) => this.props.setFrases(this.state.variables, duracion)


  render(){ 
    const reto = this.props.sesion.reto;
    const crrScreen = this.state.crrScreen;

    return(
        <React.Fragment>
          {crrScreen === 0 && <Home style={this.props.style} onClick={this.frameSesion}></Home>}
          {crrScreen === 1 && <Crear style={this.props.style} 
              onVariableChange={this.onVariableChange} reto={reto} onClick={this.frameSetTime}/>}
          {crrScreen === 2 && 
            <Tiempo onClick={(duracion) => {this.frameShareLink(); this.onDuracionChange(duracion)}} 
              style={this.props.style} reto={reto}/>}
          {crrScreen === 3 && 
            <ShareLink goToCrush={this.startCrush} style={this.props.style} reto={reto}/>}
        </React.Fragment>
    )
  }
}

export default HomeFrames;


export default class Sesion {
    constructor(id, url, idReto, reto, variables){
        this.id = id;
        this.url = url;
        this.idReto = idReto
        this.reto = reto;
        this.variables = variables;
        this.frases = "";
        this.duracion=0;
        
    };

    setId(id){this.id = id}
    setReto(reto){this.reto = reto}
    setVariables(variables){this.variables = variables}
    setIdReto(idReto){this.idReto = idReto}
    setUrl(url){this.url = url}
    setFrases(frases){this.frases = frases}
    setDuracion(duracion){this.duracion = duracion}


    getUrl(){return this.url}
}

import React, {useState} from 'react';
import {Label, Button, NotifMissingInput } from '../../Components/index'



const mystyle = {
  "textinput": {
      textDecoration: "none",
      display: "inline-block",
      border: "none", 
      marginTop: "0.5em", 
      borderRadius: "7px",
      fontSize: "14px",
      height: "2em",
      outline: "none",
      width: "100%",
      padding: '3px 40px',
  }
};

/** 
 * Muestra la pagina principal y maneja la 
 * creación del reto.
 * 
 * @version 0.1
 * @component {Home} Pagina principal
 */
function Home (props){
    //const id = "home"
    const labelText = "¡Vamos! Crea un reto";
    const buttonText = "EMPEZAR";
    const placeholderText = "Ejemplo: Reducir el tráfico de Lima";
    const [emptyInput, setEmptyInput] = useState(false)

    let goNext = () => {
      const elem = document.getElementById("inputReto")
      if (elem.value === "")
        setEmptyInput(true)
      else{
        setEmptyInput(false)
        props.onClick()
      }
    }

    return (
        <div style={props.style.divScreen} className="home-screen screen">
          <Label size={'s'} title={labelText}/>
          <div style={{'height':'10px'}}></div>
          <textarea style={mystyle.textinput} maxLength="300" className="text-ideas" id="inputReto" 
                    style={{'height':'62px', padding: '6px 15px','width':'230px', 
                        'borderRadius':'7px','fontFamily': 'Roboto'}} 
                    placeholder={placeholderText}></textarea>
          <Button input={false} buttonText={buttonText} onClick={goNext}/> 
          {emptyInput && <NotifMissingInput reto={true}/>}
        </div>
    )
}

export default Home

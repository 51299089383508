import Sesion from './Sesion'
import axios from 'axios';

const main = "https://www.crush2.ludik.pe/time_crush/public/";

/** 
 * Clase que maneja el api de los reto 
 * aqui se realizan las request post y get de la aplicación 
 * 
 * @version 0.2 - Agrega
 * @class {ApiReto} Manejo del Api
 */
export default class ApiReto{
    constructor(){
        this.sesion = {}
        this.apiKey = ""
        this.iniciarReto = this.iniciarReto.bind(this)
    }

    async iniciarReto() {
        let rqt = () => (axios.get(`${main}reto`)
        .then(function (response) {
            // handle success
            const data = response.data
            return [data.apiToken, data.codigoReto]
        }))

        const data = await rqt()

        let url = `${window.location.href}`.split("/")
        let pageURL = `${url[0]}//${url[2]}/${data[1]}/`;

        this.apiKey = data[0]
        this.sesion = new Sesion(data[1],pageURL, [], [], [])
        return this.sesion
    }

    crearReto = async (nombre) => {
        let sesion = this.sesion
        let api = this.apiKey

        let rq = () => axios.post( `${main}reto/${sesion.id}`, {
            nombre: nombre,
            api_token: api,
          }).then((response) => {console.log(response); return response.data.id})
          .catch(function (error) {return "";})

          const id = await rq()
          this.sesion.setIdReto(id);

          let url = `${window.location.href}`.split("/")
          let pageURL = `${url[0]}//${url[2]}/${this.sesion.id}/${id}`;
          this.sesion.setUrl(pageURL);
    }

    actualizarReto = async (all_variables, duracion) => {
        let variables = "["
        all_variables.map(e => (variables+=`"${e}",`))
        variables = variables.substring(0, variables.length - 1);
        variables += "]"
        
        let sesion = this.sesion
        let api = this.apiKey
        axios.post(`${main}actualizar/${sesion.id}`, {
            "nombre" : sesion.reto,
            "id": this.sesion.idReto,
            "api_token": api,
            "variables": variables,
            "pregunta": sesion.reto,
            "duracion": duracion,
        })
        .then((response)=>console.log(response))
        return "OK"
    }

    checkReto = async (url, codigo, id) => {
        let link = `${main}reto/cargar/${codigo}/${id}`
        let rqt = async (codigo, id) => (
        axios.post( `${link}`)
        .then((response) =>{
            console.log(response)
            if(response.status === 200)
                return [response.data.api_token, response.data.pregunta];
            else
                return "error";
        }))

        const data = await rqt()
        this.apiKey = data[0]
        //TODO añadir request a las variables

        this.sesion = new Sesion(codigo, url, id, data[1], [], data[2])

        return this.sesion 
    }
    getCrrSession  = () => {
        return this.sesion
    }

    async getFrases(id) {
        console.log(id, this.sesion.id)
        let link = `${main}ideas/frases/${this.sesion.id}`
        let rq = () => (axios.post( `${link}`,{
            'id': id,
            'api_token': this.apiKey,
        })
        .then((response) =>{
            console.log("FRASES", response.data)
            return response.data.frases
        }))
        .catch((res) => res)
        
        let frases = await rq()
        this.sesion.setFrases(frases)
        return frases 
    }

    async addNewUser(username) {
        let link = `${main}nuevoUser/registrar/${this.sesion.id}`
        return(axios.post( `${link}`,{
            'nombre_usuario': username,
            'id': this.sesion.idReto,
            'api_token': this.apiKey,
        })
        .then((response) =>{
            console.log(response)
            return response.data;
        })
        .catch((err => console.log(err))))
    }
    
    async getListadeIdeas(userid) {
        let link = `${main}calificar/${this.sesion.id}`
        return (axios.post( `${link}`,{
            'jugador_id': userid,
            'id': this.sesion.idReto,
            'api_token': this.apiKey,
        })
        .then((response) =>{
            console.log("Ideas", JSON.parse(response.data.ideas))
            return (JSON.parse(response.data.ideas))
        })
        .catch((err => console.log(err))))
    }

    async calificarIdeas(ideasIDs, rankingsPnts, userid) {
        console.log("Calificar",  ideasIDs, rankingsPnts, userid)
        let link = `${main}calificar/guardar/${this.sesion.id}`
        axios.post( `${link}`,{
            'jugador_id': userid,
            'id': this.sesion.idReto,
            'api_token': this.apiKey,
            "ideas": JSON.stringify(ideasIDs),
            "ranking": JSON.stringify(rankingsPnts),
        })
        .then((response) =>{
            console.log(response.data)
            return response.data
        })
        .catch((err => console.log(err)))
    }

    async saveIdea(idea) {
        let link = `${main}ideas/guardar/${this.sesion.id}`
        axios.post( `${link}`,{
            "id": this.sesion.idReto,
            "api_token": this.apiKey,
            "idea": idea.content,
            "id_variable": idea.variable,
            "id_significado": idea.id,
            "jugador_id": idea.user
        })
        .then((response) =>{
            console.log(response)
        })
        .catch((err => console.log(err)))
    }

    async getRanking(idea) {
        let link = `${main}ranking/${this.sesion.id}`
        return (axios.post( `${link}`,{
            "id": this.sesion.idReto,
            "api_token": this.apiKey,
        })
        .then((response) =>{
            console.log("Ranking", response)
            return JSON.parse(response.data.ranking)
        })
        .catch((err => console.log(err))))
    }

    async getListadeEspera() {
        let link = `${main}listaEspera/${this.sesion.id}`
        return (axios.post( `${link}`,{
            "id": this.sesion.idReto,
            "api_token": this.apiKey,
        })
        .then((response) =>{
            return JSON.parse(response.data.personas)
        })
        .catch((err => console.log(err))))
    }

    //Cambiar estado en 
    async cambiarEstado(usuario, estado) {
        let link = `${main}cambiar/${this.sesion.id}`
        return (axios.post( `${link}`,{
            "id": this.sesion.idReto,
            "api_token": this.apiKey,
            "id_usuario": usuario, 
            "estado": estado,
        })
        .then((response) => console.log("cambiarestado", response.data))
        .catch((err => console.log(err))))
    }
}

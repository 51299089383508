import React from 'react'
import {Label, Button } from '../../Components/index'

import { useHistory } from "react-router-dom";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const mystyle = {
    'ideas':{
        'display':'grid',
        'gridTemplateColumns': '1fr 6fr',
        'marginBottom' : '15px',
        width: '100%',
    },
    'idea':{
        'backgroundColor': 'white',
        'color': 'black',
        'borderRadius': '5px',
        'padding': '0.5em 6px',
    },
    'number':{
        'fontWeight':'600',
        'gridRowStart': '1',
        'gridRowEnd': '3',
    }
}

/** 
 * Permite la descarga de un array de objetos
 * a excel. 
 * 
 * @version 0.1
 * @component {ExportCSV} Descarga de Excel
 */
const ExportCSV = ({csvData, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button onClick={(e) => exportToCSV(csvData,fileName)}  buttonText={"¡DESCARGAR!"}/>
    )
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  }

/** 
 * Muestra un ranking de las mejores ideas por
 * cada una de las variables. Muestra boton para volver 
 * al inicio o para descarga excel de este ranking
 * 
 * @version 0.1
 * @component {Ranking} Ranking de Ideas
 */
function Ranking(props){
    const buttonText = "VOLVER AL INICIO";
    const title = "RANKING"
    let newstyle = Object.assign({}, props.style.divScreen);
    newstyle.overflowX = 'hidden';

    let IdeasbyVariables = (variable) => {
        const crrIdeas = props.ideas.filter((e)=> e.variable === variable)
        console.log("RANK", props.ideas, variable, crrIdeas)

        return (crrIdeas.map((e, i) =>
            (<div key={i} style={mystyle.ideas}>
                <div style={mystyle.number}>
                    <Label size={'b'} title={`${i+1}`} />
                </div>
                <div style={mystyle.idea}>
                    <Label size={'xs'} title={`${Object.keys(e)[0]}`} />
                </div>
            </div>)
        ))
    };

    
    let Ideas = () => {
        let variables = [...new Set(props.ideas.map(item => (item.variable)))]
        return <div style={newstyle} className="ranking-ideas">
            {variables.map((e, i) =>{
                return (
                <React.Fragment>
                    <Label size={'m'} title={e.capitalize()} />
                    {IdeasbyVariables(e)}
                </React.Fragment>
            )})}
        </div>;
    };

    const history = useHistory();
    let csvData = []
    props.ideas.map((e, i) => 
        {console.log("CSV", e); 
        csvData.push({Id: i, Puntaje:e[Object.keys(e)[0]], Usuario: e.usuario, Variable: e.variable, Idea: Object.keys(e)[0]})})

    const file_name = `${props.reto}`
    return (
        <div style={{'display':'flex', 'flexDirection': 'column'}} className="ranking-screen">
            <Label size={'b'} title={title} />
            <Ideas/>
            <ExportCSV csvData={csvData} fileName={file_name} />
            <Button onClick={() => {history.push('/'); props.goBack() }} buttonText={buttonText} />
        </div>
    ); 
}

export default Ranking
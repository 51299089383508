import React, {useState} from 'react'
import {Label, Button } from '../../Components/index'

import Idea from './Idea'

const getVariables = (ideas) => [...new Set(ideas.map(item => (item.variable_texto)))];

/** 
 * Muestra la ideas por variables y permite
 * votar por cada una de ellas 
 * 
 * @version 0.1
 * @component {Vote} Vista para votar por la ideas
 */
function Vote(props){
    let newstyle = Object.assign({}, props.style.divScreen);
    newstyle.overflowX = 'hidden';

    let [crrVar, setVar] = useState(0)

    function votar(){
        let variables = getVariables(props.ideas)
        console.log("VOTAR!", crrVar+1, variables.length, crrVar+1>=variables.lenght)
        if (crrVar+1>=variables.length){
            console.log("Last Vote!!")
            props.onClick(variables[crrVar])
        }
        else{
            console.log("Next Vote!!")
            props.votar(variables[crrVar])
            setVar(crrVar+1)
        }
    }

    let VoteIdeas = ({variable}) => {
        return <div style={newstyle} className="vote-ideas">
            {props.ideas.map((e, i) => {
                if (e.variable_texto === variable)
                    return <Idea idea={e} index={i}/>
            }
        )}</div>;
    };    

    const topLabel = <span>¿Cuál es la mejor idea para 
            <span style={{color:'rgb(67, 225, 222)'}}> {getVariables(props.ideas)[crrVar]}</span>?
        </span>

  
    const underLaber= "(ponle un puntaje del 0 al 5)"
    let variables = getVariables(props.ideas)

    return (
        <div style={props.style.divScreen}  className="vote-screen">
            <Label size={'b'} title={topLabel} />
            <Label size={'s'} title={underLaber} />
            <VoteIdeas variable={variables[crrVar]} />
            <Button buttonText={"SIGUIENTE"} onClick={votar}/>
        </div>
    ); 
}

export default Vote
import React from 'react';


/** 
 * El Boton y hace cosas de botones
 * 
 * @version 0.1
 * @component {Button} Se presiona y hace algo
 */
function Button (props){
    let mystyle = {
        "button": {
            color: "white",
            padding: "5px 10px",
            display: "inline-block",
            textAlign: "center",
            border: "none", 
            width: (props.full && !props.full) ? '80%' : props.short ? '30%' : "100%",
            marginTop: "1em", 
            marginBottom: props.short ? '1em' : "auto",
            borderRadius: "7px",
            outline: "none",
            fontWeight: '700',
            transition: 'all 0.4s ease-in-out',
            cursor: 'pointer',
            fontSize: '14px',
        }
    };

    return (
        <React.Fragment>
        <button style={mystyle.button} onClick={props.onClick}>
            {props.buttonText}
        </button>
        </React.Fragment>
    )
}
export default Button
/** 
 * Recibe el tiempo (segundos y minutos) y lo muestra 
 * en la view
 * 
 * @version 0.1
 * @component {Timer} Tiempo en la aplicación
 * @author [Maria Lopez](mailto:maria@ludik.pe)
 */
class User {
    constructor(n, name){
        this.id = n;
        this.user = name;
        this.ideas = []
        this.estado = 0;
    }

    agregarIdea(idea){
        this.ideas.append(idea);
    }

    cambiarEstado(estado){this.estado=estado}
}

export default User
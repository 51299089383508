import React from 'react'

let mystyle = {
    "xxs": {
        fontSize: '11px',
        padding: '0.1em',
        margin: '0.1em',

    },
    "xs": {
        fontSize: '12px',
        padding: '0.1em',
        margin: '0.1em',

    },
    "s": {
        fontSize: '14px',
        padding: '0.1em',
        margin: '0.1em',

    },
    "m": {
        fontSize: '16px',
        padding: '0.1em',
        margin: '0.1em',
    },
    "b": {
        fontSize: '18px',
        padding: '0.1em',
        margin: '0.1em',
        fontWeight: '700',
    }
    
};

function Label(props){
    let currentStyle = (props.size === "s" ? mystyle.s : 
        (props.size === "m" ? mystyle.m : (props.size === "xs" ? mystyle.xs :
        (props.size === "xxs" ? mystyle.xxs : mystyle.b))))

    const fweight = (props.extra || props.size === "b") ? 700 : 400;
    return(
        <p style={{...currentStyle, fontWeight: fweight }} name={props.title}>
            {props.title}
        </p>
    )
}

export default Label;